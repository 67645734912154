import classNames from 'classnames';
import { ReactNode } from 'react';

type MessageType = 'info' | 'warning' | 'error' | 'success';

export interface MessageProps {
  type: MessageType;
  children: ReactNode;
  square?: boolean;
}

export function ButtonMessage({ message, children, ...props }: { message: string } & MessageProps): JSX.Element {
  return (
    <Message {...props}>
      <div className="flex space-x-2 items-center">
        <div className="whitespace-nowrap">{children}</div>
        <div>{message}</div>
      </div>
    </Message>
  );
}

export default function Message({ type, children, square }: MessageProps): JSX.Element {
  return (
    <div
      className={classNames('p-3 text-gray-900 w-full text-sm', {
        'border-blue-500 bg-blue-50': type === 'info',
        'border-yellow-500 bg-yellow-50': type === 'warning',
        'border-red-500 bg-red-50': type === 'error',
        'border-green-500 bg-green-50': type === 'success',
        'rounded-theme border border-l-[4px]': !square,
        'border-b': square,
      })}
    >
      {children}
    </div>
  );
}
